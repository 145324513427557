import {
  Alert,
  Container,
  Icon,
  Loading,
  NotificationActions,
  SectionTitle,
  Wizard,
  formatUtils,
  withAuth
} from '@elotech/components';
import { AvaliacaoQuickView, PessoasQuickView } from 'itbi-common/components';
import {
  AvaliacaoService,
  DeclaracaoService,
  ParametroService,
  ProprietarioService,
  SegmentoService,
  UploadService,
  withService
} from 'itbi-common/service';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import swal from 'sweetalert2';

import { Roles } from '../../roles';
import DocumentosAvulsosSection from './documentos/DocumentosAvulsosSection';
import DocumentosImovelSection from './documentos/DocumentosImovelSection';
import IdentificacaoStep from './identificacao/IdentificacaoStep';
import DadosImovelRuralSection from './imovel/DadosImovelRuralSection';
import DadosImovelUrbanoSection from './imovel/DadosImovelUrbanoSection';
import DadosTransferenciaSection from './imovel/DadosTransferenciaSection';
import ObservacaoSection from './imovel/ObservacaoSection';
import ResumoDeclaracao from './ResumoDeclaracao';
import SegmentoSection from './valores/SegmentoSection';
import ValoresTransferenciaSection from './valores/ValoresTransferenciaSection';

const CenteredIcon = styled(Icon)`
  margin: 10% 45%;
`;

const valoresAvaliacao = {
  valorTransacaoAvaliacao: {
    aliquota: 'aliquotaTransacao',
    campo: 'valorTransacaoItbi'
  },
  valorFinanciadoAvaliacao: {
    aliquota: 'aliquotaFinanciado',
    campo: 'valorFinanciadoItbi'
  },
  valorIsencao: {
    aliquota: 'aliquotaTransacao',
    campo: 'valorIsencao'
  },
  percentualIsencao: {
    aliquota: 'aliquotaTransacao',
    campo: 'percentualIsencao'
  }
};

const errorMessages = {
  valorTransacaoAvaliacao: 'Valor é obrigatório!',
  valorFinanciadoAvaliacao: 'Valor é obrigatório!',
  valorIsencaoMenorIgualZero: 'Valor deve ser maior que 0!',
  valorIsencaoMaiorItbi:
    'Valor da Isenção não pode ser maior que o Valor ITBI!',
  percentualIsencaoMenorIgualZero: 'Percentual de Isenção de ser no mínimo 1%',
  percentualIsencaoMaiorCem: 'Percentual de Isenção de ser no máximo 100%',
  valorIsencao: 'Valor de isenção inválido',
  valoresDivergentesWithArbitragem:
    'Valores divergentes, informe ao menos um documento para arbitragem'
};

const isRequerenteEstaContidoNaLista = (declaracaoItbi, list = []) => {
  if (declaracaoItbi.requerente) {
    if (declaracaoItbi.requerente.procurador) {
      return true;
    }
    return list
      .map(m => m.cpfCnpj)
      .includes(declaracaoItbi.requerente && declaracaoItbi.requerente.cpfCnpj);
  }
  return list.map(m => m.cpfCnpj).includes(declaracaoItbi.requerenteCpfCnpj);
};

class DeclaracaoItbiAnaliseFormPage extends React.Component {
  static propTypes = {
    validRequerentes: PropTypes.array.isRequired,
    isProcurador: PropTypes.bool.isRequired,
    showNotification: PropTypes.func.isRequired,
    uploadService: PropTypes.shape({
      getUrlDownloadS3Declaracao: PropTypes.func.isRequired,
      getUrlUploadArquivoAvulsoDeclaracao: PropTypes.func.isRequired,
      getUrlDownloadArquivoAvulsoDeclaracao: PropTypes.func.isRequired,
      downloadFileS3: PropTypes.func.isRequired,
      uploadFileS3: PropTypes.func.isRequired
    }).isRequired,

    declaracaoService: PropTypes.shape({
      addDocumentoAvulso: PropTypes.func.isRequired,
      removeDocumentoAvulso: PropTypes.func.isRequired,
      findDeclaracaoItbiById: PropTypes.func.isRequired,
      updateCompradores: PropTypes.func.isRequired,
      updateVendedores: PropTypes.func.isRequired,
      updateAnuentes: PropTypes.func.isRequired,
      updateProprietarios: PropTypes.func.isRequired,
      definirPrincipal: PropTypes.func.isRequired,
      restaurarSegmentos: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
      getProprietarios: PropTypes.func.isRequired,
      deleteCompradores: PropTypes.func.isRequired,
      deleteVendedores: PropTypes.func.isRequired,
      deleteAnuentes: PropTypes.func.isRequired,
      deleteProprietarios: PropTypes.func.isRequired,
      deferir: PropTypes.func.isRequired,
      indeferir: PropTypes.func.isRequired
    }).isRequired,

    parametroService: PropTypes.shape({
      loadAllParametros: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    declaracaoItbi: undefined,
    originalDeclaracao: undefined,
    step1: {
      valid: true,
      errorMessage: undefined
    },
    step2: {
      valid: true,
      errorMessage: undefined
    },
    step3: {
      valid: true,
      errorMessage: undefined
    },
    step4: {
      valid: true,
      errorMessage: undefined
    },
    step5: {
      valid: true,
      errorMessage: undefined
    },
    submitting: false,
    loadingImovelIdentificacao: false,
    vinculos: [],
    errors: {
      valorTransacaoAvaliacao: false,
      valorFinanciadoAvaliacao: false,
      valorIsencaoMenorIgualZero: false,
      valorIsencaoMaiorItbi: false,
      percentualIsencaoMenorIgualZero: false,
      percentualIsencaoMaiorCem: false,
      valoresDivergentesWithArbitragem: false
    },
    showAvaliacoes: false,
    ultimasAvaliacoes: undefined,
    pessoaQuickView: undefined,
    parametros: undefined
  };

  componentDidMount() {
    const {
      match: { params },
      proprietarioService,
      parametroService
    } = this.props;

    this.onGetDeclaracao(params.id);
    proprietarioService
      .loadVinculos()
      .then(response => {
        if (response.data) {
          this.setState({
            vinculos: response.data
          });
        }
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao carregar os vínculos dos proprietários.'
          },
          error
        );
      });

    parametroService
      .loadAllParametros()
      .then(response => this.setState({ parametros: response.data }))
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao carregar os parâmetros de ITBI.'
          },
          error
        );
      });
  }

  onGetDeclaracaoSuccess = response => {
    this.setState({
      declaracaoItbi: {
        ...response.data,
        segmentos: response.data.segmentos || []
      },
      originalDeclaracao: response.data
    });
  };

  onGetDeclaracao = id => {
    this.props.declaracaoService
      .findDeclaracaoItbiById(id)
      .then(this.onGetDeclaracaoSuccess)
      .catch(this.onGetDeclaracaoError);
  };

  onFinishAnalise = async () => {
    const { declaracaoItbi } = this.state;
    const result = await Alert.question({
      title: 'Finalizou a análise?',
      text: 'Escolha entre as opções para finalizar a análise.',
      confirmButtonText: 'Deferir',
      cancelButtonText: 'Indeferir',
      allowOutsideClick: true
    });
    if (result.value) {
      this.validacoesPreDeferimento()
        .then(() => {
          this.deferir(declaracaoItbi);
        })
        .catch(errorMessage => {
          Alert.warning({
            title: 'Problemas ao deferir declaração',
            text: errorMessage
          });
        });
    } else if (result.dismiss === swal.DismissReason.cancel) {
      this.indeferir(declaracaoItbi);
    }
  };

  onDownload = (documento, nomeArquivo) => event => {
    event.preventDefault();

    const { uploadService } = this.props;
    const { declaracaoItbi } = this.state;
    uploadService
      .getUrlDownloadS3Declaracao({
        declaracao: declaracaoItbi.id,
        documento: documento.documentoItbi.id,
        arquivo: nomeArquivo
      })
      .then(response => {
        uploadService.downloadFileS3(response.data.url);
      });
  };

  onBeforeChange = (oldStepData, newStepData) => {
    const { declaracaoItbi } = this.state;
    const oldStep = { ...oldStepData, valid: true };

    if (newStepData.index < oldStepData.index) {
      return { oldStepData: oldStep, newStepData };
    }

    oldStep.valid = [
      () => true,
      () => true,
      this.onValidateDadosIdentificacao,
      this.onValidateValoresTransferencia
    ].reduce((currentValue, validatorFn, index) => {
      if (index >= oldStepData.index && index < newStepData.index) {
        return validatorFn(declaracaoItbi) && currentValue;
      }
      return currentValue;
    }, true);

    const stepResumoAnaliseDeclaracaoItbi = 4;

    if (newStepData.index === stepResumoAnaliseDeclaracaoItbi) {
      this.onNormalizeValoresAvaliacao(declaracaoItbi);
    }

    this.setState({ loadingImovelIdentificacao: true });
    return this.props.declaracaoService
      .update(declaracaoItbi.id, this.normalizeData(declaracaoItbi))
      .then(() => {
        this.props.showNotification({
          level: 'success',
          message: 'Dados salvos com sucesso'
        });
        this.setState({
          originalDeclaracao: declaracaoItbi,
          loadingImovelIdentificacao: false
        });
        return {
          oldStepData: oldStep,
          newStepData
        };
      })
      .catch(error => {
        this.setState({ loadingImovelIdentificacao: false });
        this.props.showNotification({
          level: 'warning',
          message: 'Não foi possível salvar as alterações.'
        });
        return Promise.reject(error);
      });
  };

  informaValoresFinanciamento = declaracaoItbi =>
    declaracaoItbi.tipoItbi.financiado || declaracaoItbi.tipoItbi.anuencia;

  informaValoresFinanciamentoTotal = declaracaoItbi =>
    this.informaValoresFinanciamento(declaracaoItbi) &&
    declaracaoItbi.tipoItbi.permiteTotalmenteFinanciado;

  informaValorIsencao = declaracaoItbi => declaracaoItbi.tipoItbi.isencao;

  isValidValorTransacaoAvaliacao = valorTransacaoAvaliacao =>
    !valorTransacaoAvaliacao || valorTransacaoAvaliacao <= 0;

  isValidValorIsencao = (valorIsencao, percentualIsencao) =>
    (!valorIsencao || valorIsencao <= 0) && percentualIsencao === undefined;

  valorIsencaoMaiorItbi = (
    valorIsencao,
    valorTransacaoItbi,
    percentualIsencao
  ) => valorTransacaoItbi < valorIsencao && percentualIsencao === undefined;

  percentualIsencaoMaiorCem = percentualIsencao =>
    percentualIsencao > 100 && percentualIsencao !== undefined;

  percentualIsencaoMenorIgualZero = percentualIsencao =>
    (!percentualIsencao || percentualIsencao < 1) &&
    percentualIsencao !== undefined;

  existeDocumentoParaArbitramento = declaracaoItbi => {
    return declaracaoItbi.documentosAvulsos.some(doc => doc.arbitragem);
  };

  errorValidators = {
    valorTransacaoAvaliacao: declaracaoItbi =>
      this.informaValoresFinanciamentoTotal(declaracaoItbi)
        ? false
        : this.isValidValorTransacaoAvaliacao(
            declaracaoItbi.valorTransacaoAvaliacao
          ),
    valorFinanciadoAvaliacao: declaracaoItbi =>
      this.informaValoresFinanciamento(declaracaoItbi) &&
      (!declaracaoItbi.valorFinanciadoAvaliacao ||
        declaracaoItbi.valorFinanciadoAvaliacao <= 0),
    valorIsencao: declaracaoItbi =>
      this.informaValorIsencao(declaracaoItbi)
        ? this.isValidValorIsencao(
            declaracaoItbi.valorIsencao,
            declaracaoItbi.percentualIsencao
          )
        : false,
    valorIsencaoMaiorItbi: declaracaoItbi =>
      this.informaValorIsencao(declaracaoItbi)
        ? this.valorIsencaoMaiorItbi(
            declaracaoItbi.valorIsencao,
            declaracaoItbi.valorTransacaoItbi,
            declaracaoItbi.percentualIsencao
          )
        : false,
    percentualIsencaoMenorIgualZero: declaracaoItbi =>
      this.informaValorIsencao(declaracaoItbi)
        ? this.percentualIsencaoMenorIgualZero(declaracaoItbi.percentualIsencao)
        : false,
    percentualIsencaoMaiorCem: declaracaoItbi =>
      this.informaValorIsencao(declaracaoItbi)
        ? this.percentualIsencaoMaiorCem(declaracaoItbi.percentualIsencao)
        : false,
    valoresDivergentesWithArbitragem: declaracaoItbi =>
      this.ativaArbitramento()
        ? !this.existeDocumentoParaArbitramento(declaracaoItbi)
        : false
  };

  onValidateValoresTransferencia = declaracaoItbi => {
    const errors = {
      valorTransacaoAvaliacao: this.errorValidators.valorTransacaoAvaliacao(
        declaracaoItbi
      ),
      valorFinanciadoAvaliacao: this.errorValidators.valorFinanciadoAvaliacao(
        declaracaoItbi
      ),
      valorIsencao: this.errorValidators.valorIsencao(declaracaoItbi),
      valorIsencaoMaiorItbi: this.errorValidators.valorIsencaoMaiorItbi(
        declaracaoItbi
      ),
      percentualIsencaoMaiorCem: this.errorValidators.percentualIsencaoMaiorCem(
        declaracaoItbi
      ),
      percentualIsencaoMenorIgualZero: this.errorValidators.percentualIsencaoMenorIgualZero(
        declaracaoItbi
      ),
      valoresDivergentesWithArbitragem: this.errorValidators.valoresDivergentesWithArbitragem(
        declaracaoItbi
      )
    };

    const valid = !Object.values(errors).some(Boolean);

    if (!valid) {
      const error = Object.keys(errors)
        .filter(key => errors[key])
        .slice(0);
      const errorMessage = errorMessages[error];

      this.props.showNotification({
        level: 'error',
        message: errorMessage
      });
    }
    this.setState(state => {
      return {
        step4: {
          ...state.step4,
          valid: valid,
          errorMessage: valid ? '' : 'Campos Obrigatórios!'
        },
        errors: {
          ...state.errors,
          ...errors
        }
      };
    });

    return valid;
  };

  possuiCamposNaoPreenchidos = list => {
    return list.some(pessoa => this.validaEnderecoAise(pessoa));
  };

  validaEnderecoAise = pessoa => {
    const { cidadeLogada } = this.props;
    if (pessoa.ibge === `${cidadeLogada.codigoIbge}`) {
      return !pessoa.bairroAise || !pessoa.cidadeAise || !pessoa.logradouroAise;
    }
    return false;
  };

  compradorPossuiCamposNaoPreenchidos = compradores => {
    return compradores.some(
      comprador =>
        this.validaEnderecoAise(comprador) || this.validaRg(comprador)
    );
  };

  validaRg = comprador => {
    const { parametros } = this.state;
    return (
      parametros?.validaRgComprador &&
      comprador.tipoPessoa === 'FISICA' &&
      (!comprador.rg || !comprador.rgOrgaoEmissor || !comprador.rgUf)
    );
  };

  onValidateDadosIdentificacao = declaracaoItbi => {
    const errorMessages = {};
    if (!this.validatePercentualCompraVenda(declaracaoItbi)) {
      errorMessages.percentualCompraVenda =
        'Percentual compra deve ser igual ao de venda!';
    }

    const percentualTotalProprietario = this.getPercentual(
      declaracaoItbi.proprietarios
    );

    if (percentualTotalProprietario !== 100) {
      errorMessages.somaPercentualProprietario = `Soma do percentual de posse dos proprietários  deve ser igual a 100%. Foi informado ${percentualTotalProprietario}%`;
    }

    if (!this.validatePrincipal(declaracaoItbi)) {
      errorMessages.proprietarioPrincipal =
        'Necessário um proprietário principal';
    }

    if (declaracaoItbi.compradores.length === 0) {
      errorMessages.compradores =
        'A declaração deve ter pelo menos um comprador.';
    }

    if (this.validateVinculo(declaracaoItbi.compradores)) {
      errorMessages.vinculos = 'Existe comprador sem vínculo informado.';
    }

    if (
      declaracaoItbi.compradores.length > 0 &&
      this.compradorPossuiCamposNaoPreenchidos(declaracaoItbi.compradores)
    ) {
      errorMessages.compradores =
        'Compradores possui campos obrigatórios vazios';
    }

    if (declaracaoItbi.vendedores.length === 0) {
      errorMessages.vendedores =
        'A declaração deve ter pelo menos um vendedor.';
    }
    if (
      declaracaoItbi.vendedores.length > 0 &&
      this.possuiCamposNaoPreenchidos(declaracaoItbi.vendedores)
    ) {
      errorMessages.vendedores = 'Vendedores possui campos obrigatórios vazios';
    }

    if (this.validateVinculo(declaracaoItbi.vendedores)) {
      errorMessages.vinculos = 'Existe vendedor sem vínculo informado.';
    }

    if (
      declaracaoItbi.tipoItbi.anuencia &&
      declaracaoItbi.anuentes.length === 0
    ) {
      errorMessages.anuentes = 'A declaração deve ter pelo menos um anuente.';
    }
    if (
      declaracaoItbi.anuentes.length > 0 &&
      this.possuiCamposNaoPreenchidos(declaracaoItbi.anuentes)
    ) {
      errorMessages.anuentes = 'Anuentes possui campos obrigatórios vazios';
    }

    if (this.validateVinculo(declaracaoItbi.anuentes)) {
      errorMessages.vinculos = 'Existe anuente sem vínculo informado.';
    }

    const isRequerentePessoaFisica =
      (declaracaoItbi.requerente &&
        formatUtils.isCpf(declaracaoItbi.requerente.cpfCnpj)) ||
      formatUtils.isCpf(declaracaoItbi.requerenteCpfCnpj);

    if (isRequerentePessoaFisica) {
      if (
        !this.requerenteFazParteCompradores(declaracaoItbi) &&
        !this.state.parametros.naoValidaRequerente
      ) {
        errorMessages.requerenteOnCompradores =
          'O requerente deve estar entre os compradores.';
      }
    } else {
      if (
        !this.state.parametros?.naoValidaRequerente &&
        !this.requerenteFazParteProcesso(declaracaoItbi)
      ) {
        errorMessages.requerenteParteProcesso =
          'O requerente deve estar entre os compradores, vendedores ou anuentes.';
      }
    }

    if (this.validateVinculo(declaracaoItbi.proprietarios)) {
      errorMessages.vinculos = 'Existe proprietário sem vínculo informado.';
    }

    if (
      !this.requerenteFazParteCompradores(declaracaoItbi) &&
      this.findAnyDocumentoComProcuracaoRequerente(declaracaoItbi.documentos)
    ) {
      errorMessages.vinculos =
        'Deve ser adicionado algum documento de procuração requerente!';
    }

    const stepValido = Object.keys(errorMessages).length === 0;
    Object.values(errorMessages).forEach(message => {
      setTimeout(
        () =>
          this.props.showNotification({
            level: 'error',
            message,
            autoDismiss: 10
          }),
        100
      );
    });

    this.setState(state => {
      return {
        step3: {
          ...state.step3,
          valid: stepValido,
          errorMessage: stepValido ? '' : 'Formulário inválido!'
        }
      };
    });

    return stepValido;
  };

  requerenteFazParteCompradores = declaracaoItbi => {
    const { compradores } = declaracaoItbi;
    if (declaracaoItbi.origemServidor) {
      return true;
    }

    return isRequerenteEstaContidoNaLista(declaracaoItbi, compradores);
  };

  requerenteFazParteProcesso = declaracaoItbi => {
    const { compradores, vendedores, anuentes } = declaracaoItbi;
    if (declaracaoItbi.origemServidor) {
      return true;
    }

    return (
      isRequerenteEstaContidoNaLista(declaracaoItbi, compradores) ||
      isRequerenteEstaContidoNaLista(declaracaoItbi, vendedores) ||
      isRequerenteEstaContidoNaLista(declaracaoItbi, anuentes)
    );
  };

  validatePrincipal = declaracaoItbi => {
    return declaracaoItbi.proprietarios.some(
      proprietario => proprietario.principal
    );
  };

  validatePercentualCompraVenda = ({ compradores, vendedores }) => {
    const percentualCompra = this.getPercentual(compradores);
    const percentualVendedores = this.getPercentualVenda(vendedores);
    return percentualCompra === percentualVendedores;
  };

  validateVinculo = pessoas => {
    return pessoas.some(item => !item.vinculo);
  };

  findAnyDocumentoComProcuracaoRequerente = documentos => {
    return documentos.some(item => item.documentoItbi.procuracaoRequerente);
  };

  getPercentual = array => {
    return formatUtils.round(
      array.map(m => +m.percentual).reduce((accum, valor) => accum + valor, 0),
      2
    );
  };

  getPercentualVenda = array => {
    return formatUtils.round(
      array
        .map(m => +m.percentualVenda)
        .reduce((accum, valor) => accum + valor, 0),
      2
    );
  };

  onChange = event => {
    const { name, value, checked, type } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    this.setState(state => {
      return {
        declaracaoItbi: { ...state.declaracaoItbi, [name]: newValue }
      };
    });
  };

  onChangePercentualIsencaoItbi = event => {
    const { name, value } = event.target;
    const campoValorItbi = valoresAvaliacao[name].campo;
    const valorIsencaoPercentual =
      this.state.declaracaoItbi.valorTransacaoItbi * (value / 100);
    const valorIsencao = 'valorIsencao';

    this.setState(state => {
      return {
        declaracaoItbi: {
          ...state.declaracaoItbi,
          [name]: value,
          [campoValorItbi]: value,
          [valorIsencao]: valorIsencaoPercentual
        },
        errors: {
          [name]: false
        }
      };
    });
    return null;
  };

  onChangeValoresItbi = event => {
    const { name, value } = event.target;
    const campoValorItbi = valoresAvaliacao[name].campo;
    const aliquota = this.state.declaracaoItbi[
      `${valoresAvaliacao[name].aliquota}`
    ];
    const percentualIsencao = 'percentualIsencao';

    if (campoValorItbi === 'valorIsencao') {
      if (this.state.declaracaoItbi.percentualIsencao !== undefined) {
        this.setState(state => {
          return {
            declaracaoItbi: {
              ...state.declaracaoItbi,
              [name]: value,
              [campoValorItbi]: value,
              [percentualIsencao]: undefined
            },
            errors: {
              [name]: false
            }
          };
        });
      }
      this.setState(state => {
        return {
          declaracaoItbi: {
            ...state.declaracaoItbi,
            [name]: value,
            [campoValorItbi]: value
          },
          errors: {
            [name]: false
          }
        };
      });
    } else {
      this.setState(state => {
        return {
          declaracaoItbi: {
            ...state.declaracaoItbi,
            [name]: value,
            [campoValorItbi]: value * (aliquota / 100)
          },
          errors: {
            [name]: false
          }
        };
      });
    }
  };

  onNormalizeValoresAvaliacao = declaracaoItbi => {
    const {
      tipoItbi,
      valorTransacaoAvaliacao,
      valorFinanciadoAvaliacao,
      valorIsencao
    } = declaracaoItbi;
    if (!valorTransacaoAvaliacao) {
      declaracaoItbi.valorTransacaoAvaliacao = 0;
      declaracaoItbi.valorTransacaoItbi = 0;
      this.setState(declaracaoItbi);
    }

    if (
      !valorFinanciadoAvaliacao &&
      (tipoItbi.anuencia || tipoItbi.financiado)
    ) {
      declaracaoItbi.valorFinanciadoAvaliacao = 0;
      declaracaoItbi.valorFinanciadoItbi = 0;
      this.setState(declaracaoItbi);
    }
    if (tipoItbi.isencao && !valorIsencao) {
      declaracaoItbi.valorIsencao = 0;
    }
    if (
      valorIsencao &&
      !this.errorValidators.percentualIsencaoMaiorCem(declaracaoItbi) &&
      !this.errorValidators.percentualIsencaoMenorIgualZero(declaracaoItbi) &&
      declaracaoItbi.valorTransacaoItbi > 0
    ) {
      declaracaoItbi.valorDevido =
        declaracaoItbi.valorTransacaoItbi - valorIsencao;
    }
  };

  somaValorConstrucaoSegmento = () => {
    const {
      declaracaoItbi: { segmentos, valorTerritorialCalculado }
    } = this.state;
    const valorConstrucaoSegmentos = segmentos
      .map(segmento => segmento.valorConstrucao)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return valorConstrucaoSegmentos + valorTerritorialCalculado;
  };

  onSaveSegmento = (idSegmento, areaConstruidaAvaliacao) => {
    const {
      declaracaoItbi: { segmentos }
    } = this.state;
    this.setState({ submitting: true });

    this.props.segmentoService
      .save(idSegmento, { areaConstruidaAvaliacao })
      .then(response => {
        if (response.data) {
          const indexUpdated = segmentos.findIndex(
            segmento => segmento.id === idSegmento
          );

          const newArray = [
            ...segmentos.slice(0, indexUpdated),
            response.data,
            ...segmentos.slice(indexUpdated + 1)
          ];

          this.setState(state => ({
            declaracaoItbi: {
              ...state.declaracaoItbi,
              segmentos: newArray
            },
            submitting: false
          }));
        }
      })
      .catch(error => {
        this.setState({
          submitting: false
        });
        Alert.error({ title: 'Falha ao alterar a construção!' }, error);
      });
  };

  onDeleteSegmento = idSegmento => {
    const {
      declaracaoItbi: { segmentos }
    } = this.state;
    this.setState({ submitting: true });

    this.props.segmentoService
      .remove(idSegmento)
      .then(() => {
        const indexDeleted = segmentos.findIndex(
          segmento => segmento.id === idSegmento
        );

        const newArray = [
          ...segmentos.slice(0, indexDeleted),
          ...segmentos.slice(indexDeleted + 1)
        ];

        this.setState(state => ({
          declaracaoItbi: {
            ...state.declaracaoItbi,
            segmentos: newArray
          },
          submitting: false
        }));
      })
      .catch(error => {
        this.setState({
          submitting: false
        });
        Alert.error({ title: 'Falha ao deletar a construção!' }, error);
      });
  };

  onRestaurarSegmentos = () => {
    const {
      declaracaoItbi: { id }
    } = this.state;

    this.setState({ submitting: true });

    this.props.declaracaoService
      .restaurarSegmentos(id)
      .then(response =>
        this.setState({ declaracaoItbi: response.data, submitting: false })
      )
      .catch(error => {
        this.setState({ submitting: false });
        Alert.error(
          { title: 'Não foi possível restaurar os segmentos' },
          error
        );
      });
  };

  onSetPrincipal = person => {
    const { id } = this.state.declaracaoItbi;

    this.setState({ submitting: true });
    this.props.declaracaoService
      .definirPrincipal(id, person)
      .then(response =>
        this.setState(state => ({
          declaracaoItbi: {
            ...state.declaracaoItbi,
            proprietarios: response.data
          }
        }))
      )
      .finally(() => this.setState({ submitting: false }));
  };

  onEdit = personType => (person, position) => {
    const { [personType]: personArray, id } = this.state.declaracaoItbi;
    const { declaracaoService } = this.props;
    const updateFns = {
      compradores: declaracaoService.updateCompradores,
      vendedores: declaracaoService.updateVendedores,
      anuentes: declaracaoService.updateAnuentes,
      proprietarios: declaracaoService.updateProprietarios
    };

    this.setState({ submitting: true });
    return updateFns[personType](id, person)
      .then(response => {
        const safePosition =
          position === undefined ? personArray.length : position;
        const newArray = [
          ...personArray.slice(0, safePosition),
          { ...person, id: person.id || response.data.id },
          ...personArray.slice(safePosition + 1)
        ];

        return declaracaoService
          .getProprietarios(id)
          .then(novosProprietarios => {
            this.setState(state => ({
              declaracaoItbi: {
                ...state.declaracaoItbi,
                [personType]: newArray,
                proprietarios: novosProprietarios.data
              }
            }));
          });
      })
      .catch(() => {
        this.props.showNotification({
          level: 'error',
          message: `Falha ao atualizar ${personType}!`
        });
      })
      .finally(() => this.setState({ submitting: false }));
  };

  onUpdatePersonSucess = response => {};

  onDelete = personType => position => {
    const { [personType]: personArray, id } = this.state.declaracaoItbi;
    const { declaracaoService } = this.props;
    const deleteFns = {
      compradores: declaracaoService.deleteCompradores,
      vendedores: declaracaoService.deleteVendedores,
      anuentes: declaracaoService.deleteAnuentes,
      proprietarios: declaracaoService.deleteProprietarios
    };

    const newArray = [
      ...personArray.slice(0, position),
      ...personArray.slice(position + 1)
    ];
    this.setState({ submitting: true });
    deleteFns[personType](id, personArray[position].id)
      .then(() => {
        const getNewProprietariosPromise =
          personType === 'proprietarios'
            ? Promise.resolve({ data: newArray })
            : declaracaoService.getProprietarios(id);

        return getNewProprietariosPromise.then(novosProprietarios => {
          this.setState(state => ({
            declaracaoItbi: {
              ...state.declaracaoItbi,
              proprietarios: novosProprietarios.data,
              [personType]: newArray
            }
          }));
        });
      })
      .catch(error => {
        this.props.showNotification({
          level: 'error',
          message: `Falha ao atualizar ${personType}!`
        });
        throw error;
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  };

  deferir = declaracaoItbi => {
    const { declaracaoService, history } = this.props;

    this.setState({
      submitting: true
    });

    declaracaoService
      .deferir(declaracaoItbi.id)
      .then(async response => {
        this.setState({
          submitting: false
        });

        await Alert.success({
          title: 'Análise Finalizada',
          text: this.deferirSuccessMessage(response.data)
        });

        history.replace(`/declaracoes-itbi/${declaracaoItbi.id}/resumo`);
      })
      .catch(error => {
        this.setState({
          submitting: false
        });
        Alert.error({ title: 'Falha ao deferir pedido!' }, error);
      });
  };

  deferirSuccessMessage = declaracaoItbi => {
    switch (declaracaoItbi.situacaoItbi.name) {
      case 'AGUARDANDO_PAGAMENTO':
        return 'Débito já foi gerado. Clique no ícone do código de barras para imprimir o boleto.';
      case 'AGUARDANDO_ACEITE':
        return 'Pedido arbitrado. Aguardando informações do requerente.';
      default:
        return 'Aguardando geração do débito. Clique no ícone com o cifrão para gerar o débito.';
    }
  };

  motivoIndeferimentoValidator = value => {
    return new Promise(resolve => {
      if (value) {
        resolve();
      } else {
        resolve('Descreva o motivo do indeferimento!');
      }
    });
  };

  indeferir = async declaracaoItbi => {
    const { declaracaoService, history } = this.props;

    const result = await Alert.question({
      title: 'Qual o motivo do indeferimento?',
      input: 'textarea',
      inputPlaceholder: 'Descreva o motivo do indeferimento',
      inputValidator: this.motivoIndeferimentoValidator,
      confirmButtonText: 'Indeferir e notificar requerente',
      cancelButtonText: 'Cancelar'
    });
    if (result.value) {
      this.setState({
        submitting: true
      });

      declaracaoService
        .indeferir(declaracaoItbi.id, { motivo: result.value })
        .then(async () => {
          this.setState({
            submitting: false
          });
          await Alert.success({ title: 'Análise Finalizada' });

          history.goBack();
        })
        .catch(error => {
          this.setState({
            submitting: false
          });
          Alert.error({ title: 'Falha ao indeferir pedido!' }, error);
        });
    }
  };

  onShowAvaliacoes = () => {
    const { avaliacaoService, showNotification } = this.props;

    const {
      cadastroImobiliario,
      cadastroRural,
      tipoImovel
    } = this.state.declaracaoItbi;

    const cadastro =
      tipoImovel === 'URBANO' ? cadastroImobiliario : cadastroRural;
    this.setState({ submitting: true });
    avaliacaoService
      .getUltimasAvaliacoes(cadastro.id, tipoImovel)
      .then(response => {
        if (response.data) {
          if (response.data.length === 0) {
            showNotification({
              level: 'info',
              message: 'Não foi encontrado nenhuma avaliação para este cadastro'
            });
          } else {
            this.setState({
              ultimasAvaliacoes: response.data,
              showAvaliacoes: true
            });
          }
        }
        this.setState({ submitting: false });
      })
      .catch(() => {
        showNotification({
          level: 'error',
          title: 'Erro',
          message: 'Ocorreu um erro ao buscar as avaliações'
        });
        this.setState({ submitting: false });
      });
  };

  onCloseAvaliacoes = () => {
    this.setState({ showAvaliacoes: false });
  };

  onViewPessoa = pessoa => {
    this.setState({
      pessoaQuickView: pessoa
    });
  };

  onCloseQuickViewPessoa = () =>
    this.setState({
      pessoaQuickView: undefined
    });

  showFinishButton = () =>
    this.props.auth.hasRole(Roles.itbi_finalizar_pedido.name);

  onAddDocumentoAvulso = documento => {
    const { uploadService, showNotification, declaracaoService } = this.props;
    const { declaracaoItbi } = this.state;

    if (
      declaracaoItbi.documentosAvulsos.some(
        documentoAvulso => documentoAvulso.nome === documento.nome
      )
    ) {
      showNotification({
        level: 'warning',
        message: 'Já existe um arquivo com o mesmo nome para esta declaração.'
      });
      return Promise.reject('ALREADY_EXISTS_SAME_NAME');
    }
    this.setState({ submitting: true });
    return uploadService
      .getUrlUploadArquivoAvulsoDeclaracao(declaracaoItbi.id, documento.nome)
      .then(urlResult =>
        uploadService.uploadFileS3(urlResult.data.url, documento.arquivo)
      )
      .then(uploadResponse => {
        const url = uploadResponse.config.url.split('?')[0];
        const newDocumento = { ...documento, url };
        return declaracaoService.addDocumentoAvulso(
          declaracaoItbi.id,
          newDocumento
        );
      })
      .then(documentosResponse => {
        this.setState(state => ({
          declaracaoItbi: {
            ...state.declaracaoItbi,
            documentosAvulsos: [
              ...state.declaracaoItbi.documentosAvulsos,
              documentosResponse.data
            ]
          }
        }));
      })
      .finally(() => this.setState({ submitting: false }));
  };
  onRemoveDocumentoAvulso = documento => {
    const { declaracaoItbi } = this.state;
    const { declaracaoService } = this.props;

    this.setState({ submitting: true });
    return declaracaoService
      .removeDocumentoAvulso(declaracaoItbi.id, documento.id)
      .then(() => {
        this.setState(state => {
          const documentosAvulsos = state.declaracaoItbi.documentosAvulsos.filter(
            docAvulso => docAvulso.id !== documento.id
          );

          return {
            declaracaoItbi: { ...state.declaracaoItbi, documentosAvulsos }
          };
        });
      })
      .finally(() => this.setState({ submitting: false }));
  };

  onDownloadDocumentoAvulso = documento => {
    const { uploadService } = this.props;
    const { declaracaoItbi } = this.state;

    this.setState({ submitting: true });
    return uploadService
      .getUrlDownloadArquivoAvulsoDeclaracao(declaracaoItbi.id, documento.nome)
      .then(urlResult => uploadService.downloadFileS3(urlResult.data.url))
      .finally(() => this.setState({ submitting: false }));
  };

  normalizeData = declaracao => {
    const {
      tipoItbi,
      cadastroImobiliario,
      tipoImovel,
      cadastroRural
    } = declaracao;
    return {
      id: declaracao.id,
      tipoItbi: { id: tipoItbi.id },
      cadastroImobiliario:
        tipoImovel === 'URBANO'
          ? {
              id: cadastroImobiliario.id,
              areaTerreno: cadastroImobiliario.areaTerreno,
              areaConstruida: cadastroImobiliario.areaConstruida
            }
          : undefined,
      valorTerritorialCalculado: declaracao.valorTerritorialCalculado,
      valorTransacao: declaracao.valorTransacao,
      valorTransacaoItbi: declaracao.valorTransacaoItbi,
      valorFinanciado: declaracao.valorFinanciado,
      valorFinanciadoItbi: declaracao.valorFinanciadoItbi,
      valorTransacaoAvaliacao: declaracao.valorTransacaoAvaliacao,
      valorFinanciadoAvaliacao: declaracao.valorFinanciadoAvaliacao,
      valorIsencao: declaracao.valorIsencao,
      observacaoIsencao: declaracao.observacaoIsencao,
      observacaoCidadao: declaracao.observacaoCidadao,
      observacaoServidor: declaracao.observacaoServidor,
      compradores: declaracao.compradores,
      vendedores: declaracao.vendedores,
      anuentes: declaracao.anuentes,
      requerente: declaracao.requerente,
      responsavel: declaracao.responsavel,
      automatica: declaracao.automatica,
      documentosAvulsos: declaracao.documentosAvulsos,
      cadastroRural:
        tipoImovel === 'RURAL'
          ? {
              id: cadastroRural.id
            }
          : undefined,
      tipoImovel
    };
  };

  async validacoesPreDeferimento() {
    return Promise.all([this.validaPercentualDosProprietarios()]);
  }

  validaPercentualDosProprietarios() {
    return new Promise((resolve, reject) => {
      const { declaracaoItbi } = this.state;
      let totalPercentualDosProprietarios = declaracaoItbi.proprietarios.reduce(
        (total, proprietario) => (total += proprietario.percentual),
        0
      );

      if (parseFloat(totalPercentualDosProprietarios.toFixed(2)) !== 100) {
        return reject('Total dos proprietários difere de 100%');
      }
      resolve();
    });
  }

  ativaArbitramento() {
    const { parametros, declaracaoItbi } = this.state;
    return (
      parametros &&
      parametros.permiteContestacaoValor &&
      parametros.termoAceiteArbitramento &&
      !declaracaoItbi.tipoItbi.isencao &&
      declaracaoItbi.valorTransacaoAvaliacao > declaracaoItbi.valorTransacao
    );
  }

  render() {
    const {
      declaracaoItbi,
      step1,
      step2,
      step3,
      step4,
      step5,
      vinculos,
      ultimasAvaliacoes,
      showAvaliacoes,
      loadingImovelIdentificacao,
      submitting,
      errors,
      pessoaQuickView,
      parametros
    } = this.state;

    const { auth } = this.props;

    if (!declaracaoItbi) {
      return (
        <CenteredIcon
          size="4x"
          icon="spinner"
          spin
          primary
          style={{ margin: '10% 45%' }}
        />
      );
    }
    const valoresItbi = {
      valorTransacao: declaracaoItbi.valorTransacao,
      valorTransacaoAvaliacao: declaracaoItbi.valorTransacaoAvaliacao,
      aliquotaTransacao: declaracaoItbi.aliquotaTransacao,
      valorTransacaoItbi: declaracaoItbi.valorTransacaoItbi,
      valorIsencao: declaracaoItbi.valorIsencao
    };
    const valoresFinanciamento = {
      valorFinanciado: declaracaoItbi.valorFinanciado,
      valorFinanciadoAvaliacao: declaracaoItbi.valorFinanciadoAvaliacao,
      aliquotaFinanciado: declaracaoItbi.aliquotaFinanciado,
      valorFinanciadoItbi: declaracaoItbi.valorFinanciadoItbi
    };

    return (
      <Container title="Declaração ITBI" icon="paste">
        <Loading loading={submitting || loadingImovelIdentificacao} />
        {showAvaliacoes && (
          <AvaliacaoQuickView
            onClose={this.onCloseAvaliacoes}
            ultimasAvaliacoes={ultimasAvaliacoes}
          />
        )}
        <PessoasQuickView
          pessoa={pessoaQuickView}
          onClose={this.onCloseQuickViewPessoa}
        />
        <Wizard
          beforeChange={this.onBeforeChange}
          onFinish={this.onFinishAnalise}
          allowInvalidChange={true}
          finishButtonOnlyOnLastStep
        >
          <Wizard.Step
            stepId="1"
            label="Imóvel"
            icon="far fa-building"
            valid={step1.valid}
            errorMessage={step1.errorMessage}
          >
            <DadosTransferenciaSection
              tipoItbi={declaracaoItbi.tipoItbi}
              situacao={declaracaoItbi.situacaoItbi}
              automatica={declaracaoItbi.automatica}
              onChange={this.onChange}
            />
            <SectionTitle>Localização do Imóvel</SectionTitle>
            {declaracaoItbi.tipoImovel === 'URBANO' ? (
              <DadosImovelUrbanoSection
                dadosImovel={declaracaoItbi.cadastroImobiliario}
                onChange={this.onChange}
              />
            ) : (
              <DadosImovelRuralSection
                cadastro={declaracaoItbi.cadastroRural}
              />
            )}

            <ObservacaoSection
              observacaoCidadao={declaracaoItbi.observacaoCidadao}
              observacaoServidor={declaracaoItbi.observacaoServidor}
              onChange={this.onChange}
            />
          </Wizard.Step>

          <Wizard.Step
            stepId="2"
            label="Documentação"
            icon="far fa-file-upload"
            valid={step2.valid}
            errorMessage={step2.errorMessage}
          >
            <DocumentosImovelSection
              documentos={declaracaoItbi.documentos}
              onDownload={this.onDownload}
            />
            <DocumentosAvulsosSection
              documentos={declaracaoItbi.documentosAvulsos}
              onAdd={this.onAddDocumentoAvulso}
              onRemove={this.onRemoveDocumentoAvulso}
              onDownload={this.onDownloadDocumentoAvulso}
              showNotification={this.props.showNotification}
              filter={e => !e.arbitragem}
            />
          </Wizard.Step>
          <Wizard.Step
            stepId="3"
            label="Identificação"
            icon="far fa-users"
            valid={step3.valid}
            errorMessage={step3.errorMessage}
          >
            <IdentificacaoStep
              compradores={declaracaoItbi.compradores}
              vendedores={declaracaoItbi.vendedores}
              anuentes={declaracaoItbi.anuentes}
              proprietarios={declaracaoItbi.proprietarios}
              onEdit={this.onEdit}
              onDelete={this.onDelete}
              vinculos={vinculos}
              hasAnuentes={declaracaoItbi.tipoItbi.anuencia}
              onViewPessoa={this.onViewPessoa}
              onSetPrincipal={this.onSetPrincipal}
              validaRgComprador={parametros?.validaRgComprador ?? true}
              origemServidor={declaracaoItbi.origemServidor}
              permiteEditarProprietario={
                parametros?.permiteEditarProprietario ?? false
              }
              permiteDeletarProprietario={
                parametros?.permiteDeletarProprietario ?? false
              }
            />
          </Wizard.Step>

          <Wizard.Step
            stepId="4"
            label="Valores"
            icon="far fa-dollar-sign"
            valid={step4.valid}
            errorMessage={step4.errorMessage}
          >
            <ValoresTransferenciaSection
              tipoItbi={declaracaoItbi.tipoItbi}
              valoresItbi={valoresItbi}
              valoresFinanciamento={valoresFinanciamento}
              valorCalculado={this.somaValorConstrucaoSegmento()}
              onChangeValoresItbi={this.onChangeValoresItbi}
              onChangePercentualIsencaoItbi={this.onChangePercentualIsencaoItbi}
              errors={errors}
              errorMessages={errorMessages}
              onShowAvaliacoes={this.onShowAvaliacoes}
              hasRole={auth.hasRole}
              onChangeObservacao={this.onChange}
            />
            {declaracaoItbi.tipoImovel === 'URBANO' && (
              <SegmentoSection
                segmentos={declaracaoItbi.segmentos}
                onChange={this.onChange}
                saveSegmento={this.onSaveSegmento}
                deleteSegmento={this.onDeleteSegmento}
                restaurarSegmentos={this.onRestaurarSegmentos}
                hasRole={auth.hasRole}
              />
            )}
            {this.ativaArbitramento() && (
              <DocumentosAvulsosSection
                documentos={declaracaoItbi.documentosAvulsos}
                onAdd={this.onAddDocumentoAvulso}
                onRemove={this.onRemoveDocumentoAvulso}
                onDownload={this.onDownloadDocumentoAvulso}
                showNotification={this.props.showNotification}
                fieldMark={'arbitragem'}
                customSectionTitle={'Documentos para arbitragem'}
                filter={e => e.arbitragem}
              />
            )}
          </Wizard.Step>

          <Wizard.Step
            stepId="5"
            label="Resumo"
            icon="far fa-file-alt"
            valid={step5.valid}
            errorMessage={step5.errorMessage}
            showFinishButton={this.showFinishButton()}
          >
            <ResumoDeclaracao
              declaracao={declaracaoItbi}
              onViewPessoa={this.onViewPessoa}
            />
          </Wizard.Step>
        </Wizard>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  declaracaoService: DeclaracaoService,
  uploadService: UploadService,
  proprietarioService: ProprietarioService,
  segmentoService: SegmentoService,
  avaliacaoService: AvaliacaoService,
  parametroService: ParametroService
})(DeclaracaoItbiAnaliseFormPage);

const ComponentWithAuth = withAuth(ComponentWithService);

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return currentUser && currentUser.id
    ? {
        validRequerentes:
          [...currentUser.usuariosProcurador, currentUser] || [],
        isProcurador: Boolean(currentUser.procurador),
        cidadeLogada: state.cidade.cidadeLogada
      }
    : {
        validRequerentes: [],
        isProcurador: false,
        cidadeLogada: state.cidade.cidadeLogada
      };
};

const ConnectedComponent = connect(mapStateToProps, {
  showNotification: NotificationActions.showNotification
})(ComponentWithAuth);

export {
  ConnectedComponent as default,
  DeclaracaoItbiAnaliseFormPage,
  isRequerenteEstaContidoNaLista
};
