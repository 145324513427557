import { ActionsGroup, Col, Row, formatUtils } from '@elotech/components';
import { PedidoActionButton } from 'itbi-common/components';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const IdentificacaoRow = ({
  pessoa,
  vendedor,
  onEdit,
  onDelete,
  onViewPessoa,
  proprietario,
  onChangePrincipal,
  canEdit,
  canDelete,
  cidadeLogada,
  permiteEditarProprietario,
  permiteDeletarProprietario
}) => {
  let styleError = {};
  if (
    `${cidadeLogada.codigoIbge}` === pessoa.ibge &&
    (!pessoa.cidadeAise ||
      !pessoa.bairroAise ||
      !pessoa.logradouroAise ||
      !pessoa.tipoLogradouroAise)
  ) {
    styleError = { borderLeft: '3px solid #C7382D' };
  }

  return (
    <div style={styleError}>
      <Row>
        <Col sm={3}>
          <b className="dd-title">Vínculo</b>
          <div className="dd-content">
            {pessoa.vinculo ? pessoa.vinculo.descricao : ''}
          </div>
        </Col>
        <Col sm={5}>
          <b className="dd-title">Nome</b>
          <div className="dd-content">{`${formatUtils.formatCpfCnpj(
            pessoa.cpfCnpj
          )} - ${pessoa.nome}`}</div>
        </Col>
        <Col sm={2}>
          <b className="dd-title">Percentual</b>
          <div className="dd-content right">{pessoa.percentual}%</div>
        </Col>
        {vendedor && (
          <Col sm={2}>
            <b className="dd-title">Perc. Venda</b>
            <div className="dd-content right">{pessoa.percentualVenda}%</div>
          </Col>
        )}
        {proprietario ? (
          <>
            {pessoa.principal && (
              <div className="form-group">
                <label htmlFor={pessoa.id}>Principal</label>
              </div>
            )}
            <ActionsGroup>
              {!pessoa.principal && (
                <PedidoActionButton
                  key="prioritario-button"
                  icon="check"
                  title="Definir como principal"
                  onClick={onChangePrincipal}
                />
              )}
              <PedidoActionButton
                data-test-id="buttonView"
                key="view-button"
                icon="eye"
                title="Visualizar"
                onClick={() => onViewPessoa(pessoa)}
              />
              {(permiteEditarProprietario || pessoa.remanescente) &&
                canEdit && (
                  <PedidoActionButton
                    onClick={onEdit}
                    title="Editar"
                    icon={'pencil-alt'}
                    data-test-id="buttonEditar"
                  />
                )}
              {(permiteDeletarProprietario || pessoa.remanescente) &&
                canDelete && (
                  <PedidoActionButton
                    key="trash-button"
                    icon="trash-alt"
                    title="Remover"
                    onClick={onDelete}
                  />
                )}
            </ActionsGroup>
          </>
        ) : (
          <ActionsGroup>
            <PedidoActionButton
              data-test-id="buttonView"
              key="view-button"
              icon="eye"
              title="Visualizar"
              onClick={() => onViewPessoa(pessoa)}
            />
            {canEdit && (
              <PedidoActionButton
                onClick={onEdit}
                title="Editar"
                icon={'pencil-alt'}
                data-test-id="buttonEditar"
              />
            )}
            {canDelete && (
              <PedidoActionButton
                onClick={onDelete}
                title="Excluir"
                icon={'trash-alt'}
                data-test-id="buttonRemover"
              />
            )}
          </ActionsGroup>
        )}
      </Row>
    </div>
  );
};

IdentificacaoRow.propTypes = {
  pessoa: PropTypes.object.isRequired,
  vendedor: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  cidadeLogada: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    vinculos: state.proprietario.vinculos,
    cidadeLogada: state.cidade.cidadeLogada
  };
};

const ConnectedComponent = connect(mapStateToProps, null)(IdentificacaoRow);

export { ConnectedComponent as default, IdentificacaoRow };
