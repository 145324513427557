const getFields = (userService, tipoItbiOptions = []) => {
  return [
    {
      label: 'Número do Pedido',
      name: 'numero',
      type: 'NUMBER'
    },
    {
      label: 'Número do ITBI',
      name: 'codigoItbi',
      type: 'NUMBER'
    },
    {
      label: 'Ano do ITBI',
      name: 'exercicio',
      type: 'NUMBER'
    },
    {
      label: 'Número do Cadastro',
      name: 'cadastroImobiliario.cadastro',
      type: 'NUMBER'
    },
    {
      label: 'Tipo ITBI',
      name: 'tipoItbi.id',
      type: 'ENUM',
      options: tipoItbiOptions
    },
    {
      label: 'Data Lançamento',
      name: 'data',
      type: 'DATE'
    },
    {
      label: 'Valor Transação',
      name: 'valorTransacao',
      type: 'NUMBER'
    },
    {
      label: 'Valor Financiado',
      name: 'valorFinanciado',
      type: 'NUMBER'
    },
    {
      label: 'Nome Requerente',
      name: 'requerente',
      type: 'AUTOCOMPLETE',
      loadOptions: searchParameter =>
        userService.searchUsuarioByNome(searchParameter),
      getOptionValue: option => option.id,
      getOptionLabel: option => option.nome
    },
    {
      label: 'CPF/CNPJ Requerente',
      name: 'requerenteCpfCnpj',
      type: 'STRING'
    },
    {
      label: 'Responsável',
      name: 'responsavel',
      type: 'AUTOCOMPLETE',
      loadOptions: searchParameter =>
        userService.searchUsuarioOnSameEntidade(searchParameter),
      getOptionValue: option => option.id,
      getOptionLabel: option => option.nome
    },
    {
      label: 'Origem',
      name: 'origemServidor',
      type: 'ENUM',
      options: [
        {
          name: 'true',
          descricao: 'Prefeitura'
        },
        {
          name: 'false',
          descricao: 'On-line'
        }
      ]
    },
    {
      label: 'Comprador',
      name: 'compradores.pessoa.nome',
      type: 'STRING'
    },
    {
      label: 'Comprador CPF/CNPJ',
      name: 'compradores.pessoa.cpfCnpj',
      type: 'NUMBER'
    },
    {
      label: 'Vendedor',
      name: 'vendedores.pessoa.nome',
      type: 'STRING'
    },
    {
      label: 'Vendedor CPF/CNPJ',
      name: 'vendedores.pessoa.cpfCnpj',
      type: 'NUMBER'
    },
    {
      label: 'Situação ITBI',
      name: 'situacaoItbi',
      type: 'ENUM',
      options: [
        {
          name: 'AGUARDANDO_ANALISE',
          descricao: 'Aguardando Análise'
        },
        {
          name: 'ANALISANDO',
          descricao: 'Em Análise'
        },
        {
          name: 'INDEFERIDO',
          descricao: 'Indeferido'
        },
        {
          name: 'AGUARDANDO_GERACAO_BOLETO',
          descricao: 'Aguardando Geração de Boleto'
        },
        {
          name: 'AGUARDANDO_NOTIFICACAO',
          descricao: 'Aguardando Notificação'
        },
        {
          name: 'AGUARDANDO_PAGAMENTO',
          descricao: 'Aguardando Pagamento'
        },
        {
          name: 'TRANSFERENCIA_MANUAL',
          descricao: 'Aguardando Transferência Manual'
        },
        {
          name: 'TRANSFERIDO',
          descricao: 'Transferido'
        },
        {
          name: 'AGUARDANDO_ACEITE',
          descricao: 'Aguardando Aceite'
        },
        {
          name: 'EM_CONTESTACAO',
          descricao: 'Em contestação'
        }
      ]
    }
  ];
};

export { getFields };
