import {
  Alert,
  Container,
  FAB,
  FormikCheckBox,
  FormikHtmlEditor,
  FormikInputInteger,
  FormikSelect,
  Loading,
  NotificationActions,
  Row,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import { ParametroService } from 'itbi-common/service';
import { Parametro } from 'itbi-common/types';
import React from 'react';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';

type Props = {
  showNotification(notification: Notification): void;
};

type State = {
  loading: boolean;
  parametro: Parametro;
};

const tipoValidacao = [
  {
    tipo: 'ISSE_MARINGA',
    descricao: 'Integração com o acesso cidadão de Maringá.'
  }
];

const validationSchema = Yup.object().shape({
  tipoValidacao: Yup.string().when('validaPermissaoParcelamento', {
    is: true,
    then: function() {
      return Yup.string()
        .label('Tipo de Validação')
        .required();
    }
  })
});

class ParametroFormPage extends React.Component<Props, State> {
  state: State = {
    loading: false,
    parametro: {
      id: '',
      numeroLeiLaudo: 0,
      anoLeiLaudo: 0,
      avaliacaoManualLaudo: false,
      validaPermissaoParcelamento: false,
      tipoValidacao: '',
      instrucaoAcessoParcelamento: '',
      conteudoTelaInicial: '',
      utilizaFluxoSimplificado: false,
      utilizaCadastroRural: false,
      validaRgComprador: true,
      buscaImobiliarioPorInscricao: false,
      naoValidaRequerente: false,
      bloqueiaItbiCadInadimplente: false,
      permiteContestacaoValor: false,
      validaPedidoDuplicado: false,
      termoAceiteArbitramento: false,
      txtTermoAceiteArbitramento: '',
      diasDecursoContestacao: 0,
      permiteEditarProprietario: false,
      permiteDeletarProprietario: false
    }
  };

  onSave = (parametro: Parametro) => {
    this.setState({ loading: true });
    ParametroService.save(parametro)
      .then(() => {
        this.props.showNotification({
          level: 'success',
          message: 'Parâmetro alterado com sucesso.'
        });
      })
      .catch(error => {
        Alert.error(
          { title: 'Ocorreu um problema ao tentar salvar o parâmetro.' },
          error
        );
      })
      .finally(() => this.setState({ loading: false }));
  };

  componentDidMount() {
    this.setState({ loading: true });
    ParametroService.loadAllParametros()
      .then(response => {
        this.setState({ parametro: response.data });
      })
      .catch(error => {
        Alert.error(
          { title: 'Ocorreu um erro ao buscar os parâmetros do ITBI.' },
          error
        );
      })
      .finally(() => this.setState({ loading: false }));
  }

  onEditorChange = (value: string) => {
    const { parametro } = this.state;

    this.setState({
      parametro: { ...parametro, instrucaoAcessoParcelamento: value }
    });
  };

  onChangeSelect = (
    formikProps: FormikProps<any>,
    event: React.ChangeEvent<any>
  ) => {
    if (event.target.value === '') {
      return formikProps.setFieldValue('tipoValidacao', undefined);
    }
    formikProps.setFieldValue('tipoValidacao', event.target.value);
  };

  render() {
    const { loading, parametro } = this.state;

    return (
      <Container title="Parâmetros" icon="list-alt">
        <Loading loading={loading} />
        <Formik<Parametro>
          enableReinitialize
          initialValues={parametro}
          validationSchema={() => validationSchema}
          onSubmit={this.onSave}
          render={(formikProps: FormikProps<Parametro>) => (
            <React.Fragment>
              <Row>
                <FormikInputInteger
                  size={2}
                  name="numeroLeiLaudo"
                  label="Lei"
                />
                <FormikInputInteger
                  size={2}
                  name="anoLeiLaudo"
                  label="Ano da Lei"
                />
                <FormikCheckBox
                  id="avaliacaoManualLaudo"
                  name="avaliacaoManualLaudo"
                  label="Avaliação Manual do Laudo"
                  size={2}
                  noLabel={false}
                />
              </Row>
              <Row>
                <FormikCheckBox
                  id="validaPermissaoParcelamento"
                  name="validaPermissaoParcelamento"
                  label="Valida permissão do parcelamento"
                  size={2}
                  noLabel={false}
                />
                <FormikSelect
                  disabled={!formikProps.values.validaPermissaoParcelamento}
                  name="tipoValidacao"
                  label="Tipo de Validação"
                  options={tipoValidacao || []}
                  getOptionLabel={option => option.descricao}
                  getOptionValue={option => option.tipo}
                  onChange={(event: React.ChangeEvent<any>) =>
                    this.onChangeSelect(formikProps, event)
                  }
                  size={4}
                  fast={false}
                />
              </Row>
              <Row>
                <FormikCheckBox
                  id="utilizaFluxoSimplificado"
                  name="utilizaFluxoSimplificado"
                  label="Utiliza fluxo simplificado da declaração de ITBI"
                  size={3}
                  noLabel={false}
                />
                <FormikCheckBox
                  id="utilizaCadastroRural"
                  name="utilizaCadastroRural"
                  label="Utiliza cadastro rural no ITBI"
                  size={3}
                  noLabel={false}
                />
              </Row>
              <Row>
                <FormikCheckBox
                  id="validaRgComprador"
                  name="validaRgComprador"
                  label="Valida Rg do comprador na declaração de ITBI"
                  size={3}
                  noLabel={false}
                />
                <FormikCheckBox
                  id="buscaImobiliarioPorInscricao"
                  name="buscaImobiliarioPorInscricao"
                  label="Permite buscar cadastro imobiliário por inscrição"
                  size={3}
                  noLabel={false}
                />
              </Row>
              <Row>
                <FormikCheckBox
                  id="permiteEditarProprietario"
                  name="permiteEditarProprietario"
                  label="Permite editar proprietário"
                  hint="Possibita habilitar botão de editar Proprietário em Declaração de ITBI, independente de ser proprietário remanescente."
                  size={3}
                  noLabel={false}
                />
                <FormikCheckBox
                  id="permiteDeletarProprietario"
                  name="permiteDeletarProprietario"
                  label="Permite deletar proprietário"
                  hint="Possibita habilitar botão de remover Proprietário em Declaração de ITBI, independente de ser proprietário remanescente."
                  size={3}
                  noLabel={false}
                />
              </Row>
              <Row>
                <FormikCheckBox
                  id="naoValidaRequerente"
                  name="naoValidaRequerente"
                  label="Descomplica ITBI"
                  hint="Com o parâmetro ativo, possibilitará qualquer usuário realizar o pedido de declaração de ITBI. Desta forma o mesmo não precisa estar envolvido na negociação"
                  size={3}
                  noLabel={false}
                />
                <FormikCheckBox
                  id="bloqueiaItbiCadInadimplente"
                  name="bloqueiaItbiCadInadimplente"
                  label="Bloqueia geração de ITBI para cadastros inadimplentes"
                  hint="Com o parâmetro ativo, será bloqueado a geração de ITBI para cadastros inadimplentes, tanto para o servidor, quanto para o cidadão."
                  size={3}
                  noLabel={false}
                />
                <FormikCheckBox
                  id="permiteContestacaoValor"
                  name="permiteContestacaoValor"
                  label="Permite contestação de valor"
                  hint="Quando este parâmetro estiver ativo, o sistema passará o controle de Arbitramento do ITBI, para o cidadão dar o Aceite e Possibilitar Contestar o pedido."
                  size={3}
                  noLabel={false}
                />
              </Row>

              <Row>
                <FormikCheckBox
                  id="termoAceiteArbitramento"
                  name="termoAceiteArbitramento"
                  label="Permite Utilizar Termo de Aceite no Arbitramento"
                  hint="Quando este parâmetro estiver ativo, o sistema passará a utilizar o texto cadastrado no Termo de Aceite para o Arbitramento do ITBI."
                  size={3}
                  noLabel={false}
                />
                <FormikCheckBox
                  id="validaPedidoDuplicado"
                  name="validaPedidoDuplicado"
                  label="Valida pedido duplicado"
                  hint="Quando este parâmetro estiver ativo, o sistema passará a verificar se os mesmos compradores de um mesmo cadastro estão presentes em mais de uma declaração em andamento."
                  size={3}
                  noLabel={false}
                />
              </Row>

              <Row>
                <FormikInputInteger
                  id="diasDecursoContestacao"
                  name="diasDecursoContestacao"
                  label="Quantidade de dias para expiração da contestação"
                  hint="Quando este parametro estiver ativo, o sistema passará a verificar diariamente se os pedidos de ITBI que estão em contestação, tiveram o prazo de contestação expirado."
                  size={3}
                />
              </Row>

              <Row>
                <FormikHtmlEditor
                  name="instrucaoAcessoParcelamento"
                  size={12}
                  label="Instrução para Acesso ao Parcelamento"
                  onChange={value =>
                    formikProps.setFieldValue(
                      'instrucaoAcessoParcelamento',
                      value
                    )
                  }
                />
              </Row>
              <Row>
                <FormikHtmlEditor
                  name="conteudoTelaInicial"
                  size={12}
                  label="Conteúdo da Tela Inicial"
                  onChange={value =>
                    formikProps.setFieldValue('conteudoTelaInicial', value)
                  }
                />
              </Row>

              <Row>
                <FormikHtmlEditor
                  name="txtTermoAceiteArbitramento"
                  size={12}
                  label="Texto do Termo de Aceite do Arbitramento"
                  disabled={!formikProps.values.termoAceiteArbitramento}
                  fast={false}
                  onChange={value =>
                    formikProps.setFieldValue(
                      'txtTermoAceiteArbitramento',
                      value
                    )
                  }
                />
              </Row>

              <div className="btn-save">
                <FAB
                  data-test-id="buttonSave"
                  icon="check"
                  iconColor="white"
                  title="Alterar Parâmetro"
                  onClick={formikProps.submitForm}
                />
              </div>
            </React.Fragment>
          )}
        />
      </Container>
    );
  }
}

const ConnectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(ParametroFormPage);

export { ConnectedComponent as default, ParametroFormPage };
