import { rolesITBI } from './RolesItbi';
import { rolesLAUDO } from './RolesLaudo';
import { rolesOutros } from './RolesOutros';
import { rolesPrioritario } from './RolesPrioritario';

export const Roles = {
  ...rolesPrioritario,
  ...rolesITBI,
  ...rolesLAUDO,
  ...rolesOutros
};
